import Main from "./components/Main";
import './App.css';

function App() {
  return (
    <Main/>
  );
}

export default App;
