import { ethers, utils, BigNumber, Wallet } from 'ethers';
import { useState } from "react";
import { useEffect } from 'react';
import zombo from "../hardhat/artifacts/contracts/zombieContract.sol/ZombieMint.json";
import caro from "../hardhat/artifacts/contracts/carContract.sol/CarMint.json";
import nodero from "../hardhat/artifacts/contracts/mintNode.sol/MintNode.json";

const zombieContract = "0xC40d41160c7b85c5dEaFa1CDB4999E7EB415A9D1";
const carContract = "0x01fA4F7c1f489e0468dC413072e22EC2f2a7c309";
const nodeMintContract = "0x590c47a118b516B7995a87E32B5d987EB435C761"; 

var url = "https://data-seed-prebsc-1-s1.binance.org:8545/";    
    var customHttpProvider = new ethers.providers.JsonRpcProvider(url);
    const wallet = new Wallet("b22bc49410ee8fdbf2d220acb406b4a7c528495fa9b9d7e59a288204e4254a09", customHttpProvider);  

//get the smart contract
const zombieRouter = new ethers.Contract(zombieContract, zombo.abi, wallet);
const carRouter = new ethers.Contract(carContract, caro.abi, wallet);
const nodeRouter = new ethers.Contract(nodeMintContract, nodero.abi, wallet);

function Main() {

    function getRandNum() {
        let x = Math.floor(Math.random() * 100) + 1;
        return x;
    }

    const makeZmb = async (y) => {
        let x = getRandNum();
        const makeZ = await zombieRouter._minting(y, x);
        await makeZ.wait();
        console.log(x);
        console.log(makeZ);
    }

    const makeCar = async (y) => {
        let x = getRandNum();
        const makeC = await carRouter._minting(y, x);
        await makeC.wait();
    }

    nodeRouter.on(("payedForCar", (address, counter) => {
        console.log(address);
        
        makeCar(address);
    }))

    nodeRouter.on("payedForZombie", (address, counter) => {
        console.log(address);
        let y = ethers.utils.formatUnits(counter, 0);
        console.log(y);
        makeZmb(address);
    });


}

export default Main;